@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

@layer base {
  :root {
    --wordpress: #30353a;
    --elementor: #8d0039;
    --css: #264de4;
    --html: #e44d26;
    --bootstrap: #821af7;
    --react: #66dcfa;
    --tailwind: #40bef6;
    --woocommerce: #7f54b3;
  }

  html {
    font-family: Poppins, system-ui, sans-serif;
    scroll-behavior: smooth;
    @apply bg-gradient-to-r from-sky-100/30 from-10% to-sky-900/30 to-90%;
  }

  ::selection {
    @apply bg-gray-800 text-white;
  }

  .container{
    @apply px-6
  }

  .active {
    @apply text-gray-900 bg-white/50 transition-all px-4 py-2 rounded-lg;
  }

  .btn-primary {
    @apply px-4 py-2 text-white hover:shadow-lg shadow-sm transition-all bg-gray-800 hover:bg-gray-600 outline-none rounded mt-6;
  }

  .btn-secondary {
    @apply px-4 py-2 text-black hover:shadow-lg shadow-md transition-all bg-white/20 outline-none rounded mt-6 flex items-center gap-4;
  }

  .btn-mobile-secondary {
    @apply px-4 py-2 text-black shadow-lg transition-all bg-white/50 outline-none rounded mt-6 flex items-center gap-4;
  }

  .box {
    @apply bg-slate-200 hover:bg-gray-700 border-2 border-gray-900 text-black hover:text-white transition-all p-6 shadow-md hover:shadow-2xl w-full lg:w-3/12 min-h-[200px] h-fit rounded;
  }

  .box-tech {
    @apply bg-slate-200 border-2 border-gray-900 text-black transition-all p-6 shadow-md hover:shadow-2xl w-full lg:w-3/12 min-h-[200px] h-fit rounded relative;
  }

  .wordpress {
    @apply bg-[var(--wordpress)] text-white text-xs px-3 py-1 capitalize before:content-['Wordpress'] tracking-widest z-30 rounded;
  }

  .elementor {
    @apply bg-[var(--elementor)] text-white text-xs px-3 py-1 capitalize before:content-['Elementor'] tracking-widest z-30 rounded;
  }

  .html {
    @apply bg-[var(--html)] text-white text-xs px-3 py-1 uppercase before:content-['HTML'] tracking-widest z-30 rounded;
  }

  .css {
    @apply bg-[var(--css)] text-white text-xs px-3 py-1 uppercase before:content-['CSS'] tracking-widest z-30 rounded;
  }

  .bootstrap {
    @apply bg-[var(--bootstrap)] text-white text-xs px-3 py-1 capitalize before:content-['Bootstrap'] tracking-widest z-30 rounded;
  }

  .react {
    @apply bg-[var(--react)] text-black text-xs px-3 py-1 capitalize before:content-['React'] tracking-widest z-30 rounded;
  }

  .tailwind {
    @apply bg-[var(--tailwind)] text-white text-xs px-3 py-1 capitalize before:content-['TailwindCSS'] tracking-widest z-30 rounded;
  }

  .woocommerce {
    @apply bg-[var(--woocommerce)] text-white text-xs px-3 py-1 capitalize before:content-['WooCommerce'] tracking-widest z-30 rounded;
  }

  .socials {
    @apply text-gray-800 p-2 hover:text-gray-600;
  }
}

/* mobile menu starts */
.menu-icon {
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  width: 100%;
  height: 2px;
  background-color: black;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.open .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.open .line:nth-child(2) {
  opacity: 0;
}

.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

#home,
#kontakt,
#projects,
#project-detail {
  min-height: 70vh;
}

@media only screen and (max-width: 768px) {
  #home,
  #kontakt,
  #projects,
  #project-detail {
    min-height: auto;
  }
}